import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "my-2 px-10" }
const _hoisted_2 = { class: "flex space-x-4 mb-1" }
const _hoisted_3 = { class: "text-neutral-dark" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "flex justify-center min-h-screen my-10" }
const _hoisted_6 = { class: "sm:mx-auto w-full max-w-6xl" }
const _hoisted_7 = { class: "flex flex-col lg:flex-row gap-2 pb-10 w-full" }
const _hoisted_8 = { class: "flex flex-col lg:w-3/4 gap-2" }
const _hoisted_9 = { class: "flex flex-col gap-4 text-cafe-gray" }
const _hoisted_10 = { class: "text-xs" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "flex flex-col lg:w-1/4 gap-2" }
const _hoisted_14 = {
  key: 0,
  class: "flex justify-center items-center py-4 gap-2"
}
const _hoisted_15 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ol", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, { to: "/admin/account-registration" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.SYSTEM.account_registrations), 1)
            ]),
            _: 1
          })
        ]),
        _cache[7] || (_cache[7] = _createElementVNode("li", null, ">", -1)),
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_router_link, { to: `` }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.SYSTEM.register_client), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode($setup["AccountInformationForm"], {
                  accountData: $setup.accountData,
                  "onUpdate:accountData": _cache[0] || (_cache[0] = ($event: any) => (($setup.accountData) = $event)),
                  isAccountInfoValid: $setup.childValid.accountInfo,
                  "onUpdate:isAccountInfoValid": _cache[1] || (_cache[1] = ($event: any) => (($setup.childValid.accountInfo) = $event))
                }, null, 8, ["accountData", "isAccountInfoValid"]),
                ($setup.accountData?.ClientType !== undefined)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode($setup["CompanyInformationForm"], {
                        companyData: $setup.companyData,
                        "onUpdate:companyData": _cache[2] || (_cache[2] = ($event: any) => (($setup.companyData) = $event)),
                        isCompanyInfoValid: $setup.childValid.companyInfo,
                        "onUpdate:isCompanyInfoValid": _cache[3] || (_cache[3] = ($event: any) => (($setup.childValid.companyInfo) = $event))
                      }, null, 8, ["companyData", "isCompanyInfoValid"]),
                      (
                      $setup.accountData.ClientType === $setup.RESUITE_ROLE.SupplyChainPartner
                    )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode($setup["BuyerInformationForm"], {
                              buyerData: $setup.buyerData,
                              "onUpdate:buyerData": _cache[4] || (_cache[4] = ($event: any) => (($setup.buyerData) = $event)),
                              isBuyerInfoValid: $setup.childValid.buyerInfo,
                              "onUpdate:isBuyerInfoValid": _cache[5] || (_cache[5] = ($event: any) => (($setup.childValid.buyerInfo) = $event))
                            }, null, 8, ["buyerData", "isBuyerInfoValid"])
                          ]))
                        : _createCommentVNode("", true),
                      _createVNode($setup["DocumentsInformationForm"], {
                        documentData: $setup.documentData,
                        "onUpdate:documentData": _cache[6] || (_cache[6] = ($event: any) => (($setup.documentData) = $event))
                      }, null, 8, ["documentData"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode($setup["StatusCard"])
          ])
        ]),
        ($setup.isShowButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("button", {
                class: "btn btn--green-primary px-10 py-2 disabled:bg-status-draft disabled:text-white text-md rounded-md",
                disabled: !$setup.isFormValid,
                onClick: _withModifiers($setup.handleSubmit, ["prevent"])
              }, " Submit ", 8, _hoisted_15)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}