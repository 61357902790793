import { defineComponent as _defineComponent } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { SYSTEM } from "@ems/locales/system";
import { ref, watch } from "vue";
import AccountInformationForm from "@ems/containers/Tenant/TheForm/AccountInformation.Form.vue";
import CompanyInformationForm from "@ems/containers/Tenant/TheForm/CompanyInformation.Form.vue";
import BuyerInformationForm from "@ems/containers/Tenant/TheForm/BuyerInformation.Form.vue";
import DocumentsInformationForm from "@ems/containers/Tenant/TheForm/DocumentsInformation.Form.vue";
import StatusCard from "@/components/Common/StatusCard.vue";
import {
  IAccountInformation,
  IBuyerInformation,
  ICompanyInformation,
  IDocumentsInformation,
  IRegisterClientRequest,
  ISubmitRegistrationResponse,
} from "@/models/Tenant";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { RESUITE_ROLE, SUPPLYCHAIN_PARTNER } from "@ems/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegisterClient',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const isFormValid = ref(false);
const isShowButton = ref(false);
const childValid = ref({
  accountInfo: false,
  companyInfo: false,
  buyerInfo: true,
});

const accountData = ref<IAccountInformation>();
const companyData = ref<ICompanyInformation>();
const documentData = ref<IDocumentsInformation>();
const buyerData = ref<IBuyerInformation>();

const handleSubmit = async () => {
  isFormValid.value = false;
  const registerRequest: IRegisterClientRequest = {
    ClientType: accountData.value!.ClientType!,
    Email: accountData.value!.Email,
    Country: companyData.value?.Country,
    Province: companyData.value?.Province,
    CompanyRegistrationNumber: companyData.value?.CompanyRegistrationNumber,
    CompanyName: companyData.value!.CompanyName,
    Address: companyData.value?.Address,
    PhoneCode: companyData.value?.PhoneCode,
    ContactNumber: companyData.value?.ContactNumber,
    CompanyRegistrationDocument:
      documentData.value?.CompanyRegistrationDocument,
    LetterOfAuthorization: documentData.value?.LetterOfAuthorization,
    OtherRelevantDocuments: documentData.value?.OtherRelevantDocuments,
    BuyerInformation: buyerData.value,
  };
  try {
    const response = await TenantUserModule.registerClientAction(
      registerRequest
    );
    if (response && response.InvitationId) {
      router.replace(
        `/admin/account-registration/${response.InvitationId}/register-detail`
      );
    }
    if (TenantUserModule.errorRegisterClientGetter) {
      isFormValid.value = true;
    }
  } catch (error) {
    console.log(error);
  }
};

watch(
  childValid,
  (newValue) => {
    if (newValue.accountInfo && newValue.companyInfo && newValue.buyerInfo) {
      isFormValid.value = true;
    } else {
      isFormValid.value = false;
    }
  },
  { deep: true }
);

watch(
  accountData,
  (newValue) => {
    if (newValue?.ClientType !== undefined) isShowButton.value = true;
  },
  { deep: true }
);

const __returned__ = { route, router, isFormValid, isShowButton, childValid, accountData, companyData, documentData, buyerData, handleSubmit, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get SYSTEM() { return SYSTEM }, ref, watch, AccountInformationForm, CompanyInformationForm, BuyerInformationForm, DocumentsInformationForm, StatusCard, get IAccountInformation() { return IAccountInformation }, get IBuyerInformation() { return IBuyerInformation }, get ICompanyInformation() { return ICompanyInformation }, get IDocumentsInformation() { return IDocumentsInformation }, get IRegisterClientRequest() { return IRegisterClientRequest }, get ISubmitRegistrationResponse() { return ISubmitRegistrationResponse }, get TenantUserModule() { return TenantUserModule }, get RESUITE_ROLE() { return RESUITE_ROLE }, get SUPPLYCHAIN_PARTNER() { return SUPPLYCHAIN_PARTNER } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})